.logo-marquee {
    width: 100%;
    display: inline-flex;
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: flex-start;
    mask-image: linear-gradient(to right, transparent 0, black 128px, black calc(100% - 128px), transparent 100%);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    @media (min-width: 768px) {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .list-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        animation: infinite-scroll 80s linear infinite;

        @keyframes infinite-scroll {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(-100%);
            }
        }

        @media (min-width: 768px) {
            justify-content: start !important;
        }

        & > li {
            list-style: none !important;
            margin-left: 0.25rem;
            margin-right: 0.25rem;

            @media (min-width: 768px) {
                margin-left: 0.5rem !important;
                margin-right: 0.5rem !important;
            }
        }

        & > img {
            max-width: none;
        }

        .img-box {
            display: grid;
            place-items: center;
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            img {
                display: block;
                max-width: 100px;
                max-height: 50px;
                height: auto;
                width: auto;

                @media (min-width: 768px) {
                    max-width: 130px !important;
                    max-height: 80px !important;
                }
            }
        }


    }


}