$dark: #202020;
$primary: #0973BB;
$secondary: #202020;
$light: #F8F9FA;
$brand: #F35903;
$yellow: #FAA001;


$enable-negative-margins: true;
$container-max-widths: (
  sm: 540px,
  md: 1080px,
  lg: 1280px,
  xl: 1366px,
  xxl: 1440px,
);

@import "./core/tabler.scss";
@import "./fonts/inter/inter.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import './component/hero.scss';
@import './component/text.scss';
@import './component/marquee.scss';
@import './component/cards.scss';


html {
}

html,
body,
#root,
#root > div {

}

body {
  background-color: #fff ;
  overflow-x: hidden !important;
}

// font size
//==================================fs=========================================//
.fs-1 {
    font-size: calc(1.375rem + 1.5vw)!important;
}
.fs-2 {
    font-size: calc(1.325rem + .9vw)!important;
}
.fs-3 {
    font-size: calc(1.3rem + .6vw)!important;
}
.fs-4 {
    font-size: calc(1.275rem + .3vw)!important;
}
.fs-5 {
    font-size: 1.25rem!important;
}
.fs-6 {
    font-size: 1rem!important;
}
.fs-7 {
    font-size: .9rem!important;
}
.fs-8 {
    font-size: .8rem!important;
}
.fs-9 {
    font-size: .6rem!important;
}

// font weight
.fw-light {
    font-family: 'Inter', sans-serif;
    font-weight: 200!important;
}

.fw-normal {
    font-family: 'Inter', sans-serif;
    font-weight: 400!important;
}

.fw-medium {
    font-family: 'Inter', sans-serif;
    font-weight: 600!important;
}

.fw-semibold {
    font-family: 'Inter', sans-serif;
    font-weight: 700!important;
}

.fw-bold {
    font-family: 'Inter', sans-serif;
    font-weight: 800!important;
}

.fw-black {
    font-family: 'Inter', sans-serif;
    font-weight: 900!important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// home
/* Style the video: 100% width and height to cover the entire window */
#intro-video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $dark, $alpha: 0.7);
  z-index: 2;
}

.text-overlay {
  margin-top: -50px;
  .home-hero-text {
    position: relative;
    z-index: 3;

    h1 {
      background-color: rgba($color: $dark, $alpha: 0.7);
      background-blend-mode: color;
      display: inline;
    }
  }
}

.why-envio {
  position: relative;
  height: 400px;

  img {
    height: 400px;
  }

  .description {
    background-color: rgba($color: $dark, $alpha: 0.5);
    padding: 20px 30px;
    position: absolute;
    bottom: 0;
    border-end-end-radius: 15px;
    border-end-start-radius: 15px;
  }
}

.categories {
  .categories-container {
    height: 500px;
    color: #fff;
  }

  .slick-prev:before {
    content: "\2190" !important;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 30px;
    line-height: 1;
    opacity: .75;
    color: #202020;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .carousel {
    width: auto !important;
    img {
      height: 80px;
    }
  }
}

.integrasi {
  position: absolute;
  left: 55%;
  bottom: 150px;
  color: #fff !important;

  .points {
    margin-bottom: 2rem !important;

     h4 {
      font-size: 1.25rem!important;
    }
  }
}

.about {
  background-image: url("../assets/about.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #F8F9FA ;
  padding-top: 100px;
  padding-bottom: 150px;
}

.form-control {
  border-radius: 8px;
  border: 1px solid rgba($color: #6C757D, $alpha: 0.3);
  background-color: #fff;
  font-size: 18px !important
}

.bg-primary-lt {
  background-color: rgba($color: $primary, $alpha: 0.5);
}

.solution {
  left: 150px;
}


@media only screen and (max-width: 1280px) {
  .integrasi {
    bottom: 25px;
    width: 700px;

     .points {
        margin-bottom: 1rem !important;

        h4 {
          font-size: 1rem!important;
        }
      }
  }

  .solution {
    left: 50px;
  }
}

@media only screen and (max-width: 1366px) {
  .integrasi {
    bottom: 50px;
    width: 700px;

     .points {
        margin-bottom: 1rem !important;

        h4 {
          font-size: 1rem!important;
        }
      }
  }
}

@media only screen and (max-width: 1440px) {
  .integrasi {
    bottom: 80px;
    width: 700px;

     .points {
        margin-bottom: 1rem !important;

        h4 {
          font-size: 1rem!important;
        }
      }
  }
}

@media only screen and (max-width: 1024px) {
  .integrasi {
    bottom: 20px;
    width: 700px;

     .points {
        margin-bottom: 1rem !important;

        h4 {
          font-size: 1rem!important;
        }
      }
  }
}

@media only screen and (max-width: 768px) {
  .integrasi {
    bottom: 20px;
    width: 600px;

     .points {
        margin-bottom: 1rem !important;

        h4 {
          font-size: 1rem!important;
        }
      }
  }

  .solution {
    left: 0px;
  }
}

@media only screen and (max-width: 760px) {
  .styles-module_item-container__a8zaY {
    width: 100% !important;
  }

  .categories {
    .categories-container {
      color: $dark !important;
      height: 300px !important;
      border-radius: 30px;
      text-align: center;
      border: 1px solid #F8F9FA;
    }

    .slick-list {
      margin-left: 10px;
    }

    div>button[data-direction=left], div>button[data-direction=right] {
      height: 50px;
    }
    .carousel {
      img {
        height: 50px;
      }
    }
  }

  .integrasi {
    position: relative !important;
    left: 0 !important;
    width: 100vw !important;
    color: #202020 !important;
  }

  .about {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .fs-3 {
    font-size: 20px !important;
  }

  .fs-5 {
    font-size: 16px !important;
  }

  .fs-6 {
    font-size: 13px !important;
  }

  .solution {
    left: 0px;
  }

}


.strikethrough {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      right: 0;
      border-top: 2px solid ;
      border-color: red;
      transform: rotate(5deg)
    }
}

.price-carousel {
  height: 270px !important;

  @media (min-width: 768px) {
    height: 300px !important;
  }

  .slick-dots {
    li {
      button {
          &::before {
            color: #fff;
          }
        }
      &.slick-active {
        button {
          &::before {
            color: $yellow !important;
          }
        }
      }
    }
  }
}