.hero-img {
    position: relative;
    margin-top: -80px;
    width: 100vw;
    height: 100%;

    img {
        width: 100vw;
        object-fit: fill;
    }

    .caption {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media (min-width: 768px) {
            bottom: 8rem !important;
        }

        .button-action {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2.5rem;
        }
    }
}