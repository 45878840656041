.text-caption {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
}

.title {
    font-size: 1.5rem;
    line-height: 2rem;
    z-index: 10;
    font-weight: 600;

    @media (min-width: 768px) {
        font-size: 2.25rem !important;
        line-height: 2.5rem !important;
    }

    .overlay {
        position: relative;
        background-blend-mode: color;

        &.social {
            &::before {
                width: 85px !important;

                @media (min-width: 768px) {
                    width: 120px !important;
                }
            }
        }

        &::before {
            content: "";
            background-color: rgba($color: $brand, $alpha: 0.25);
            height: 15px;
            width: 122px;
            position: absolute;
            left: -10px;
            bottom: 1px;
            z-index: -1;

            @media (min-width: 768px) {
                height: 18px !important;
                width: 175px !important;
                bottom: 6px !important;
            }
        }
    }

    .overlay-right {
        position: relative;
        background-blend-mode: color;

        &.prior {
            &::before {
                width: 120px;
                right: 70px;

                @media (min-width: 768px) {
                    width: 180px !important;
                    right: -12px !important;
                }
            }
        }

        &::before {
            content: "";
            background-color: rgba($color: $brand, $alpha: 0.25);
            height: 15px;
            width: 160px;
            position: absolute;
            bottom: 1px;
            z-index: -1;

            @media (min-width: 768px) {
                height: 18px !important;
                width: 235px !important;
                bottom: 6px !important;
            }
        }
    }
}

.card-title {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
}

.card-content {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
}

.text-graph {
    font-size: 3rem;
    line-height: 1;
    font-weight: 600;
    color: $primary;
}

.text-graph-caption {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: rgb(107, 114, 128);
}