.card-wrapper {
    border: 1px solid rgba(32,32,32,0.3);
    padding: 1.5rem;
    border-radius: 0.375rem;

    img {
        width: auto;
        height: 80px;
        margin-bottom: 0.5rem;

        @media (min-width: 768px) {
           height: 120px !important;
        }
    }
}

.services-container {
    background: $primary;
    border-radius: 0.5rem;
    padding: 1.5rem;

    @media (min-width: 768px) {
        min-height: 800px;
        padding: 3rem;
        background-image: url('../../assets/graph/container.png'), url('../../assets/graph/ellipse.png'), url('../../assets/graph/container-1.png'), url('../../assets/graph/ellipse-1.png');
        background-position: left 0px top 150px, right 0px top 0px, right 0px bottom 100px, left 0px bottom 0px !important;
        background-size: auto !important;
        background-repeat: no-repeat;
    }

    .img-title {
        max-height: 100px;

        @media (min-width: 768px) {
            max-height: 300px !important;
        }
    }

    .slide-wrapper {
        width: 320px !important;

        .th {
            font-size: 10px !important;

            @media (min-width: 768px) {
                font-size: 12px !important;
            }
        }

        .tb {
            font-size: 10px !important;

            @media (min-width: 768px) {
                font-size: 14px !important;
            }
        }

        @media (min-width: 768px) {
            min-width: 900px !important;
        }
    }

    .footnote-wrapper {
        width: 320px !important;

        @media (min-width: 768px) {
            width: 870px !important;
        }
    }

}