.table {
  font-size: 12px;
  thead {
    th {
      color: $table-head-color;
      background: $table-head-bg;
      @include subheader;
      padding-top: $table-head-padding-y;
      padding-bottom: $table-head-padding-y;

      @media print {
        background: transparent;
      }

      position: relative;

      &.sorting,
      &.sorting_asc,
      &.sorting_desc {
        cursor: pointer;

        &::after {
          content: "";
          border: 4px solid transparent;
          border-top-color: $gray-400;
          position: absolute;
          z-index: 10;
          top: 25px;
          right: 8px;
        }
      }

      &.sorting,
      &.sorting_asc,
      &.sorting_desc {
        &::before {
          content: "";
          border: 4px solid transparent;
          border-bottom-color: $gray-400;
          position: absolute;
          z-index: 10;
          top: 15px;
          right: 8px;
        }
      }

      &.sorting_asc::before {
        border-bottom-color: $gray-800;
      }
      &.sorting_desc::after {
        border-top-color: $gray-800;
      }
    }
  }

  th.center,
  td.center {
    text-align: center !important;
  }

  td.action {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.table-hover {
  > tbody > tr {
    > td,
    > th {
      transition-property: background;
      transition-duration: 0.2s;
      transition-timing-function: linear;
    }
  }

  > tbody > tr:hover {
    > td,
    > th {
      cursor: pointer;
    }
  }
}

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: $gray-100;
  }
}

.table-responsive {
  margin-bottom: $spacer;

  .table {
    margin-bottom: 0;
  }
}

.table-transparent {
  thead {
    th {
      background: transparent;
    }
  }
}

.table-nowrap {
  > :not(caption) > * > * {
    white-space: nowrap;
  }
}

.table-vcenter {
  > :not(caption) > * > * {
    vertical-align: middle;
  }
}

.table-center {
  > :not(caption) > * > * {
    text-align: center;
  }
}

.td-truncate {
  max-width: 1px;
  width: 100%;
}

.table-mobile {
  @each $breakpoint, $breakpoint-max-widthin in $grid-breakpoints {
    &#{breakpoint-infix($breakpoint)} {
      @include media-breakpoint-down($breakpoint) {
        display: block;

        thead {
          display: none;
        }

        tbody,
        tr {
          display: flex;
          flex-direction: column;
        }

        td {
          display: block;
          padding: $table-cell-padding-x $table-cell-padding-y !important;
          border: none;
          color: $body-color !important;

          &[data-label] {
            &:before {
              @include subheader;
              content: attr(data-label);
              display: block;
            }
          }
        }

        tr {
          border-bottom: 1px solid $table-border-color;
        }

        .btn {
          display: block;
        }
      }
    }
  }
}
